import * as React from "react"
import Seo from "../components/seo"
import PageWrapper from  "../components/layout";

const NotFoundPage = () => (
	<PageWrapper>
		<Seo title="404: Not found" />
		<h1>404: Not Found</h1>
		<p>You just hit a route that doesn&#39;t exist... the sadness.</p>
	</PageWrapper>
)

export default NotFoundPage
